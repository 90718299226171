const backend_url = process.env.REACT_APP_BACKEND_URL
const version = process.env.REACT_APP_BACKEND_VERSION
const backend_api_url = backend_url + '/api/' + version

let sectionsPrice = {
    'graph': [10, 0],
    'page': [10, 0],
};

const sectionsPriceStr = process.env.REACT_APP_SECTION_PRICE;
if (sectionsPriceStr) {
    const sects = sectionsPriceStr.split(';')
    for (const sect of sects) {
        const [name, price1, price2] = sect.split(',')
        sectionsPrice[name] = [Number(price1), Number(price2)]
    }
}

export const dotenv = {
    is_app_free: process.env.REACT_APP_IS_APP_FREE ? parseInt(process.env.REACT_APP_IS_APP_FREE) : 0,

    is_gamificated: process.env.REACT_APP_IS_GAMIFICATED ? parseInt(process.env.REACT_APP_IS_GAMIFICATED) : 0,
    show_banners: process.env.REACT_APP_SHOW_BANNERS ? parseInt(process.env.REACT_APP_SHOW_BANNERS) : 0,

    main_project: process.env.REACT_APP_MAIN_PROJECT ? parseInt(process.env.REACT_APP_MAIN_PROJECT) : 1,
    main_product: process.env.REACT_APP_MAIN_PRODUCT ? parseInt(process.env.REACT_APP_MAIN_PRODUCT) : 1,
    main_admins: process.env.REACT_APP_MAIN_ADMINS ? process.env.REACT_APP_MAIN_ADMINS.split(',').map(Number) : [1],

    app_name: process.env.REACT_APP_SERVICE_NAME ?? 'yukee',
    public_name: process.env.REACT_APP_PUBLIC_NAME ?? 'Yukee',
    home_page_domain: process.env.REACT_APP_HOME_PAGE_DOMAIN ?? 'yukee.ai',
    sites_bace_domain: process.env.REACT_APP_SITES_BASE_DOMAIN ?? 'site.bz',
    demo_site_subdomain: process.env.REACT_APP_DEMO_SITE_SUBDOMAIN ?? 'demo',
    sites_proto: process.env.REACT_APP_SITES_PROTO ?? 'https://',
    main_page: process.env.REACT_APP_MAIN_PAGE_URL ?? '/',  // ['/project', '/universe']
    reg_start_page: process.env.REACT_APP_REG_START_PAGE,

    timezone: process.env.REACT_APP_TIMEZONE ?? 'Etc/GMT-3', // Turkey Time (UTC+3)  // or "Europe/Kiev"
    default_lang: process.env.REACT_APP_DEFAULT_LANG_CODE ?? 'en',
    language_source_mode: process.env.REACT_APP_LANGUAGE_SOURCE_MODE ?? 'backend',

    files_library: process.env.REACT_APP_FILES_LIBRARY ?? 'https://storage.cloud.net/yukee',
    docs_url: process.env.REACT_APP_DOCS_URL ?? 'https://yukee.ai/help',
    help_url: process.env.REACT_APP_HELP_URL ?? 'https://yukee.ai/help',
    main_bot_url: process.env.REACT_APP_MAIN_BOT_URL ?? 'https://t.me/yukeeai_bot',
    alerts_bot_name: process.env.REACT_APP_ALERTS_BOT_NAME ?? 'yukeebot',
    social_club_url: process.env.REACT_APP_SOCIAL_CLUB_URL ?? 'https://instagram.com/yukeeai',
    mini_app_url: process.env.REACT_APP_MINI_APP_URL,

    support_url: process.env.REACT_APP_SUPPORT_URL ?? 'https://t.me/yukeeai_bot',
    hello_template: process.env.REACT_APP_HELLO_TEMPLATE ?? 'dfac3845d9badd85fbbb3178201cd4b2',

    tiny_mce_key: process.env.REACT_APP_TINY_MCE_KEY ?? 'z7oi6vx2uv45i0plvhxp77idet6ao9055v1az4pdgd516a2q',
    metric_counter_id: process.env.REACT_APP_METRIC_COUNTER_ID ? parseInt(process.env.REACT_APP_METRIC_COUNTER_ID) : 0,
    // welcome_video: '/Welcome.mp4',

    platforms: (process.env.REACT_APP_PLATFORMS || 'tg').split(','),
    messengers: (process.env.REACT_APP_MESSENGERS || 'tg').split(','),
    contact_platforms: (process.env.REACT_APP_CONTACT_PLATFORMS || 'tg').split(','),
    messanger_contact_types: (process.env.REACT_APP_MESSENGER_CONTACT_TYPES || 'tg_id').split(','),  // only for conditions checks
    contact_types: (process.env.REACT_APP_CONTACT_TYPES || 'phone,email,tg_id').split(','),

    ig_client_id: process.env.REACT_APP_IG_CLIENT_ID || '0',

    price: sectionsPrice,
    daily_price: process.env.REACT_APP_DAILY_PRICE ? parseFloat(process.env.REACT_APP_DAILY_PRICE) : 0.01,
    permanent_price: process.env.REACT_APP_PERMANENT_PRICE ? parseFloat(process.env.REACT_APP_PERMANENT_PRICE) : 0.01,
    coins_divider: process.env.REACT_APP_COINS_DIVIDER ? parseFloat(process.env.REACT_APP_COINS_DIVIDER) : 100,

    ai_coin_factor: process.env.REACT_APP_AI_COIN_FACTOR ? parseFloat(process.env.REACT_APP_AI_COIN_FACTOR) : 1,
    user_import_example_url: process.env.REACT_APP_USER_IMPORT_EXAMPLE ?? 'https://docs.google.com/spreadsheets/u/0/',
}

export const api = {
    headers: {'Content-Type': 'application/json', 'accept': 'application/json'}
}

export const backend_api = {
    bace: backend_api_url,
    oauth: `${backend_api_url}/platform/connect`,
    admin: `${backend_api_url}/admin`,
    user: `${backend_api_url}/user`,
    field: `${backend_api_url}/field`,
    user_field: `${backend_api_url}/user/field`,
    user_contact: `${backend_api_url}/user/contact`,
    user_access: `${backend_api_url}/page/access`,
    user_utm: `${backend_api_url}/user/utm`,

    project: `${backend_api_url}/project`,
    integration: `${backend_api_url}/integration`,
    webhook: `${backend_api_url}/webhook`,
    process: `${backend_api_url}/process`,
    glob: `${backend_api_url}/glob`,
    url: `${backend_api_url}/url`,
    folder: `${backend_api_url}/folder`,
    history: `${backend_api_url}/history`,

    template_component: `${backend_api_url}/template/component`,
    template: `${backend_api_url}/template`,
    segment: `${backend_api_url}/segment`,
    role: `${backend_api_url}/role`,
    niche: `${backend_api_url}/niche`,
    answer: `${backend_api_url}/answer`,

    product: `${backend_api_url}/product`,
    category: `${backend_api_url}/product/category`,
    order: `${backend_api_url}/order`,
    discount: `${backend_api_url}/discount`,
    invoice: `${backend_api_url}/invoice`,
    utm: `${backend_api_url}/utm`,

    mail: `${backend_api_url}/mail`,
    dialog: `${backend_api_url}/dialog`,
    employee: `${backend_api_url}/employee`,

    site: `${backend_api_url}/site`,
    domain: `${backend_api_url}/domain`,
    site_tag: `${backend_api_url}/site/tag`,
    layout: `${backend_api_url}/layout`,
    theme: `${backend_api_url}/theme`,
    page: `${backend_api_url}/page`,
    ai: `${backend_api_url}/ai/chatgpt`,

    platform: `${backend_api_url}/platform`,
    file: `${backend_api_url}/file/uploadfile`,
    upload: `${backend_api_url}/file/getuploadurl`,
    language: `${backend_api_url}/settings/locale`,
    section: `${backend_api_url}/settings/section`,
}

export const screens = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
}

export const routes = {
    base: window.location.protocol + '//' + window.location.hostname,
    home: window.location.protocol + '//' + dotenv.home_page_domain,
    root: '/',
    auth: '/login',
    reg: '/reg',
    welcome: '/welcome',
    forgot: '/forgot',
    logout: '/logout',

    preloader: '/preloader',
    typography: '/typography',

    offer: '/public-offer.html',
    privacy: '/privacy-policy.html',
    agreement: '/agreement.html',

    admin: {
        core: '/admin',
        account: '/admin/account',
        safety: '/admin/safety',
        edit: '/admin/edit/:user_id',
        settings: '/admin/settings',
    },

    universe: '/universe',
    project_list: '/project',
    project_quiz: '/project/quiz/:id/:step',
    project_edit: '/project/edit/:id',
    project_item: '/project/:id',

    project: '/project/:project_id',
    template: '/template/:alias',
    oauth: '/oauth/:platform',

    gamification_list: '/project/:project_id/:section',
    gamification_edit: '/project/:project_id/:section/edit/:id',

    local: {
        default: '/about',
        settings: '/edit',
        tariff: '/tariff',
        about: '/about',

        alerts_settings: '/alerts',

        graph_list: '/graph',
        graph_edit: '/graph/edit/:id',
        graph_item: '/graph/:id',

        field_list: '/field',
        field_edit: '/field/edit/:id',

        glob_list: '/glob',
        glob_edit: '/glob/edit/:id',

        group_list: '/group',
        group_edit: '/group/edit/:id',

        dialog_list: '/dialog',
        dialog_item: '/dialog/:id',

        user_list: '/user',
        user_edit: '/user/edit/:id',
        user_profile: '/user/profile/:id',
        user_contact: '/user/edit/:user_id/contact/:id',
        user_field: '/user/edit/:user_id/field/:id',
        user_page: '/user/edit/:user_id/page/:id',
        user_utm: '/user/edit/:user_id/utm/:id',

        rating: '/rating/:code/:page',

        mail_edit: '/mail/edit/:id',
        // mail_list: '/mail/:category',
        mail_list: '/mail',

        webhook_list: '/webhook',
        webhook_edit: '/webhook/edit/:id',

        employee_list: '/employee',
        employee_edit: '/employee/edit/:id',

        url_list: '/url',
        url_edit: '/url/edit/:id',

        order_list: '/order',
        order_edit: '/order/edit/:id',

        utm_list: '/utm',
        utm_edit: '/utm/edit/:id',

        category_list: '/category',
        category_edit: '/category/edit/:id',

        product_list: '/product',
        product_edit: '/product/edit/:id',

        site_list: '/site',
        site_edit: '/site/edit/:id',
        domain_list: '/domain',
        domain_edit: '/domain/edit/:id',
        layout_list: '/layout',
        layout_edit: '/layout/edit/:id',
        theme_list: '/theme',
        theme_edit: '/theme/edit/:id',
        page_list: '/page',
        page_edit: '/page/edit/:id',
        page_item: '/site/page/:id',

        tag_list: '/tag',
        tag_edit: '/tag/edit/:id',

        section_list: '/sect',
        section_edit: '/sect/edit/:id',

        integration_list: '/integration',
        integration_edit: '/integration/edit/:id',
        integration_table: '/integration/:name',

        template_store: '/store',
        template_store_item: '/store/:alias',

        template_list: '/template',  // installed
        template_own: '/template/own',  // created
        template_item: '/template/item/:id',
        template_edit: '/template/edit/:id',
        template_data: '/template/data/:id',
        template_logic: '/template/logic/:id',
        template_fields: '/template/fields/:id',
        template_components: '/template/components/:id',
    }
}

export const field_values = {
    read_access: [
        "admin",
        "self",
        "public"
    ],
    data_type: [
        "text",
        "float",
        "array",
        "json",
        "datetime"
        // "date",
        // "time",
    ],
}

export const moment_formats = {
    time: 'HH:mm',
    time_extend: 'HH:mm:ss',
    date: 'D.MM.YYYY',
    datetime: 'D.MM.YYYY HH:mm:ss',
}

const files_library = dotenv.files_library;
const universe_base = files_library + '/Universe';
const avatars_base = universe_base + '/Avatars';
const universe_banners = universe_base + '/Banners';
const universe_characters = universe_base + '/Characters';
const universe_achievements = universe_base + '/Achievements/default';
const universe_planets = universe_base + '/Sketches/dark-sketch';
const universe_segment = universe_base + '/Segment';
const universe_section = universe_base + '/Sections';

const banners_format = '.jpg'
const sketches_format = '.png'

export const images = {
    library_url: files_library,
    logo_small: '/brand/current/logo/horizontal-color.svg',
    logo_icon: '/brand/current/favicon/favicon.svg',
    no_item_photo: '/image/no-user-2.png',
    project: universe_characters + '/cash-normal' + banners_format,
    btn_menu: '/image/btn_menu/btn_menu' + sketches_format,
    universe: {
        sections: {
            list: universe_section + '/list' + banners_format,
            page: universe_section + '/page' + banners_format,
            product: universe_section + '/product' + banners_format,
            template: universe_section + '/template' + banners_format,
            user: universe_section + '/user-off' + banners_format,
            cat: universe_section + '/cat-off' + banners_format,
            currency: universe_section + '/currency-off' + banners_format,
            money: universe_section + '/currency-off' + banners_format,
            achievement: universe_section + '/achievement-off' + banners_format,
            resource: universe_section + '/resource-off' + banners_format,
        },
        banner: {
            universe: universe_banners + '/universe' + banners_format,
            olympus: universe_banners + '/olympus' + banners_format,
            tech: universe_banners + '/tech' + banners_format,
            knowledge: universe_banners + '/knowledge' + banners_format,
            ship: universe_banners + '/ship' + banners_format,
        },
        character: {
            uki: universe_characters + '/yukee-light' + banners_format,
            yukee: universe_characters + '/yukee-light' + banners_format,
            cash: universe_characters + '/cash-normal' + banners_format,
            ai: universe_characters + '/ai-normal' + banners_format,
            help: universe_characters + '/ai-popup' + banners_format,
            // https://storage.yandexcloud.net/multy/Universe/Characters/ai-popup.webp
        },
        planet: {
            // account: '/image/universe/planets/sheep-1.png',
            // projects: '/image/universe/planets/tech.png',
            // knowledge: '/image/universe/planets/knowledge.png',

            account: universe_planets + '/sketch-ship-level-01-dark' + sketches_format,
            projects: universe_planets + '/sketch-technology-dark' + sketches_format,
            knowledge: universe_planets + '/sketch-knowledge-dark' + sketches_format,
            rating: universe_planets + '/sketch-achievements-dark' + sketches_format,
        },
        achievement: {
            unset: universe_achievements + '/unset' + sketches_format,
        },
        segment: {
            business: universe_segment + '/business' + sketches_format,
            education: universe_segment + '/education' + sketches_format,
            blogger: universe_segment + '/blogger' + sketches_format,
            expert: universe_segment + '/expert' + sketches_format,
        },
    },
    avatar: {
        project: avatars_base + '/project' + sketches_format,
        user: avatars_base + '/userman' + sketches_format,
        user_cat: avatars_base + '/usercat' + sketches_format,
    },
}

export const colors = {
    default: {
        color: '',
        backcolor: 'var(--color-white)'
    },
    inverse: {
        color: 'var(--color-white)',
        backcolor: 'var(--color-gray-8)'
    },
    primary: {
        color: 'var(--color-white)',
        backcolor: 'var(--color-primary)'
    },
    success: {
        color: 'var(--color-white)',
        backcolor: 'var(--color-success)'
    },
    warning: {
        color: '',
        backcolor: 'var(--color-warning)'
    },
    note: {
        color: '',
        backcolor: 'var(--color-note)'
    },
    danger: {
        color: 'var(--color-white)',
        backcolor: 'var(--color-danger)'
    },
}