import React, {useEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {dotenv, routes} from "../../config/config";
import {notice} from "../../library/notice";
import {
    inArray,
    ucFirst,
} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate} from "../../library/containers";
import {SetItemEditFormOrPreloader} from "../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";

const SectionEdit = () => {
    const {t} = useTranslation()
    const section = 'sect'
    const Section = ucFirst(section)

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // init states
    const [avatarUrl, setAvatarUrl] = useState('')
    const [iconUrl, setIconUrl] = useState('')

    // sync with store
    const {admin, project, sect} = useSelector(store => store)
    const item = sect.item
    const project_item = project.item;

    // get form and set values
    const [form] = Form.useForm();

    // itis form state
    const [formValues, setFormValues] = useState({
        name: '',
        title: '',
        category: '',
        fields: '',
        replace: '',
        locale: '',
        params: '',
        is_on: true,
        is_system: false,
        section: null,
        photo_url: '',
        icon_url: '',
        order: 0,
    })

    const formFields = [
        {
            name: "section",
            type: "menu",
            placeholder: t('common.placeholder.menu'),
            required: true,
            localized: true,
            values: [
                'container',
                'integration',
                'effect',
                'event',
                'condition',
                'plugin',
                'pixel',
                'sites',
                'other',
            ]
        },
        {
            name: "title",
            type: "text",
            required: true,
            placeholder: t('common.placeholder.text'),
        },
        {
            name: "name",
            type: "text",
            required: true,
            label: t('common.form.name.label'),
            desc: t('common.form.name.desc'),
            placeholder: t('common.form.name.placeholder'),
        },
        {
            name: "category",
            type: "text",
            placeholder: t('common.placeholder.text'),
        },
        {
            name: "icon_name",
            type: "icon",
            section: 'common',
        },
        {
            name: "inherit",
            type: "text",
            placeholder: t('common.placeholder.text'),
        },
        {
            name: "fields",
            type: "code",
            form: form,
            language: 'js',
            placeholder: '[{"name": "ids", "type": "tags", "features": ["adding"]}]',
        },
        {
            name: "replace",
            type: "code",
            form: form,
            language: 'js',
            placeholder: '{"field_name": {"name": "ids", "type": "menu"}}',
        },
        {
            name: "spec",
            type: "code",
            form: form,
            language: 'js',
            placeholder: '[{"type": "con", "items": []}]',
        },
        {
            name: "locale." + dotenv.default_lang,
            type: "code",
            form: form,
            language: 'js',
            placeholder: "{}",
            desc: '',
        },
        {
            name: "params",
            type: "code",
            form: form,
            language: 'js',
            placeholder: "{}",
            desc: '',
        },
        {
            name: "order",
            type: "number",
            placeholder: '0',
        },
        {
            name: "is_on",
            type: "switcher",
        },
        {
            name: "is_system",
            type: "switcher",
        },
        {
            name: "photo_url",
            type: "avatar",
            form: form,
            imageType: 'card',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: avatarUrl,
            aspect: 1.8,
            placeholder: t('common.form.photo_url.placeholder'),
        },
        {
            name: "icon_url",
            type: "avatar",
            form: form,
            imageType: 'card',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: iconUrl,
            aspect: 0,
            placeholder: t('common.form.icon.label'),
        },
    ]

    // get data from API first
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized && project_item.id && id) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id});
        }

        //eslint-disable-next-line
    }, [admin.authorized, id, project_item.id])

    // set values to FORM if correct data received
    useEffect(() => {
        if (item.id === id) {
            let values = {...item}

            if (item.fields !== null) values.fields = JSON.stringify(item.fields, null, 2)
            if (item.spec !== null) values.spec = JSON.stringify(item.spec, null, 2)
            if (item.replace !== null) values.replace = JSON.stringify(item.replace, null, 2)
            if (item.params !== null) values.params = JSON.stringify(item.params, null, 2)
            if (item.locale) {
                values.locale = {}

                //  && item.locale[dotenv.default_lang] !== null
                // values.locale[dotenv.default_lang] = JSON.stringify(item.locale[dotenv.default_lang], null, 2)

                for (const key in item.locale) {
                    if (item.locale[key] !== null) {
                        values.locale[key] = JSON.stringify(item.locale[key], null, 2)
                    }
                }
            }

            form.setFieldsValue(values)
            setFormValues(values)

            setAvatarUrl(item.photo_url)
            setIconUrl(item.icon_url)
        }
        //eslint-disable-next-line
    }, [item])

    // compose form functions
    const backToList = () => navigate(`${routes.project_list}/${project_item.id}/${section}`);
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        for (const [key, value] of Object.entries(values.locale)) {
            if (!value) continue;
            try {
                values.locale[key] = JSON.parse(String(value))
                if (typeof values.locale[key] !== 'object') {
                    console.info('JSON parse error: ', key, 'is not an object')
                    notice.error(t('error.json_parse'))
                    return false;
                }
            }
            catch (e) {
                console.error('JSON parse error for', key, '\n', e)
                notice.error(t('error.json_parse'))
                return false;
            }
        }

        for (const key of ['fields', 'replace', 'params', 'spec']) {
            if (values[key]) {
                try {
                    values[key] = JSON.parse(values[key])
                    if (typeof values[key] !== 'object') {
                        console.info('JSON parse error: ', key, 'is not an object')
                        notice.error(t('error.json_parse'))
                        return false;
                    }
                }
                catch (e) {
                    console.error('JSON parse error for', key, '\n', e)
                    notice.error(t('error.json_parse'))
                    return false;
                }
            } else {
                values[key] = null
            }
        }

        formCreateOrUpdate(Section, values, formFields, id, project_item, admin, dispatch, true)
        backToList()
    }

    let formFieldsResult = [...formFields]
    if (!item.id) formFieldsResult = formFields.filter((object) => !inArray(object.name, ['short_url']))

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={section} // for labels
                    form={form}
                    formFields={formFieldsResult}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default SectionEdit